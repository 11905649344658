import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const liminaire = () => (
  <Layout>
    <SEO title="Dernières oeillades - Défense de ..." />
    <h3 className='underline-title'>Défense de ...</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Défense formelle<br />
        de photographier l'aéroport de Bukavu<br />
        Tu photographierais les <span className="font-italic">"sosongo"</span> (1) sans intérêt<br />
        Tu abimerais tes pauses<br />
      </p>
      <p>
        Défense stricte<br />
        de photographier l'aéroport d'Orly<br />
        Tu photographierais les choses très ordinaires <br />
        les choses que même les terroristres ne photographient <br />
        et préfêrent les détruire par leurs bombes <br />
        tellement elles sont vulgaires. <br />
      </p>
      <p>
        Défense absolue<br />
        de photographier l'aéroport de New York<br />
        Il est trop photographié pour le répéter<br />
        Les satellites le photographient jour et nuit sans autorisation aucune<br />
      </p>
      <p>
        Enfin<br />
        Défense<br />
        de défendre<br />
        inutilement<br />
        les choses qui se font <br />
        sans défense aucune <br />
      </p>
    </div>
    <hr/>
    <p className="font-italic">(1) Les fausses cannes sucrières (herbes à éléphants?)</p>
    <br />
    <br />
    <Link to="/dernieres-oeillades-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default liminaire
